<template>
  <div style="margin-top:10px;">
    <van-collapse v-model="activeName"
                  accordion>
      <van-collapse-item name="1">
        <template #title>
          <div style="display: flex;justify-content: space-between;">
            <span style="font-size:16px;">审批历史</span>
            <span v-if="formNo"
                  style="color:rgb(153, 153, 153);">单号{{formNo}}</span>
          </div>
        </template>
        <van-steps active-color="#969799"
                   direction="vertical"
                   :active="historyData.filter(item => !item.startTime).length + 1"
                   class="bk_history_steps">
          <van-step>
            <span>审批结束</span>
          </van-step>
          <van-step v-for="(item, index) in historyData"
                    :key="index">
            <div class="his-container">
              <div style="position: absolute;top: 5px;color: #969799">{{item.name}}</div>
              <div style="overflow: hidden;">
                <p style="display:flex;justify-content:space-between;align-items:center;margin-bottom:5px;">
                  <span class="action">{{ item.assignee }}</span>
                  <span class="people">{{ item.endTime }}</span>
                </p>
                <span class="time">{{ item.approvalStatus}}</span>
              </div>
              <div style="margin-top: 10px"
                   v-show="item.comments">
                <span class="opinion">审批意见：{{ item.comments }}</span>
              </div>
            </div>
          </van-step>
          <van-step>
            <span>审批开始</span>
          </van-step>
        </van-steps>
      </van-collapse-item>
    </van-collapse>
  </div>
</template>

<script>
export default {
  name: 'historyPanel',
  props: {
    pid: '',
    woType: {
      type: [String],
      default: '',
    },
    formNo: {
      type: String,
      defaule: ''
    }
  },
  data () {
    return {
      historyData: [],
      formId: '',
      woCode: '',
      activeName: '1'
    };
  },
  created () {
    this.formId = this.$route.query.businessKey || this.pid;
    this.woCode = this.woType;
    if (this.woCode && this.formId) {
      this.getData();
    }
  },
  watch: {
    pid: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.formId = newVal;
        if (this.woCode && this.formId) {
          this.getData();
        }
      }
    },
    woType: {
      handler (newName, oldName) {
        if (newName) {
          this.woCode = newName;
          if (this.woCode && this.formId) {
            this.getData();
          }
        }
      },
      // 代表在wacth里声明了firstName这个方法之后立即先去执行handler方法
      immediate: true
    }
  },
  methods: {
    async getData () {
      const processId = await this.$myHttp({
        method: 'get',
        url: '/microarch/activiti/actExProcInst/findByBusinessKeyAndWoType',
        params: {
          'formId': this.formId
        },
        params: {
          businessKey: this.formId,
          woType: this.woCode
        }
      }).then(res => {
        if (res.status == 200) return res.data['procInstId']
      })
      this.$myHttp({
        method: 'post',
        url: '/microarch/activiti/task/trace/list/' + processId,
      }).then(res => {
        if (res.status == 200) {
          this.historyData = res.data.rows.reverse();
          if (this.historyData.length > 0) {
            let arrys = this.historyData.filter((task) => {
              return task['approvalStatus'] != "待处理";
            });
            this.historyData = arrys;
          }

        }
      })
    },
  }
};
</script>

<style scoped lang="less">
.his-container {
  background-color: rgba(238, 238, 238, 0.89);
  padding: 10px;
  .action {
    display: block;
    float: left;
  }
  .people {
    margin-left: 10px;
    color: #323233;
  }
  .opinion {
    margin-left: 58px;
    color: rgba(93, 93, 94, 0.89);
  }
  .time {
    color: #969799;
  }
}
</style>
<style>
.bk_history_steps .van-step__circle {
  background-color: #07c160;
}

.bk_history_steps .van-step--finish .van-step__circle,
.van-step--finish .van-step__line {
  background-color: #969799;
}

.bk_history_steps .van-step__icon--active,
.van-step__title--active {
  color: #07c160 !important;
}

.bk_history_steps .van-step__line {
  background-color: #07c160;
}

.bk_history_steps .van-step--vertical {
  padding: 30px 10px 10px 0;
}
</style>
